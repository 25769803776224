"use client";

import { getCookie, setCookie } from "cookies-next";
import { useEffect, useState } from "react";
import { Button } from "../ui/Button";

const COOKIE_NAME = "cookieConsent";

const CookieConsent: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const consentCookie = getCookie(COOKIE_NAME);

		if (!consentCookie) {
			setIsOpen(true);
		}
	}, []);

	const handleAccept = () => {
		setCookie(COOKIE_NAME, "true", { maxAge: 365 * 24 * 60 * 60 });
		setIsOpen(false);
	};

	const handleReject = () => {
		setCookie(COOKIE_NAME, "false", { maxAge: 365 * 24 * 60 * 60 });
		setIsOpen(false);
	};

	if (!isOpen) return null;

	return (
		<div className="z-[100] rounded-lg border border-border bg-background px-4 py-3 shadow-lg shadow-black/5 fixed bottom-4 left-1/2 -translate-x-1/2 sm:w-[95vw]">
			<div className="flex md:flex-col justify-between gap-3 items-center">
				<p className="text-sm">
					Сайт использует файлы cookie, чтобы обеспечить вам наилучшие впечатления от использования нашего сайта.
				</p>
				<div className="flex gap-2 max-md:flex-wrap">
					<Button size="sm" className="text-sm rounded-lg" onClick={handleAccept}>
						Принять
					</Button>
					<Button variant="outline" size="sm" className="text-sm rounded-lg" onClick={handleReject}>
						Отклонить
					</Button>
				</div>
			</div>
		</div>
	);
};

export default CookieConsent;
